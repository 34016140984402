// @file updateBodySizeCss sets the width and height of the body based on the positions of the posts.
// It's used to manipulate the size of the canvas when exporting a wall as an image.

import { debounce } from 'lodash-es'

type EventName = string
type EventUnsubscribeFunction = () => void
type EventSubscribeFunction = (updateBodySizeCss) => EventUnsubscribeFunction
type SubscribeEvent = EventName | EventSubscribeFunction
type UnsubscribeEvent = EventName | EventUnsubscribeFunction

// Maintain handles on listener and subscribed events so we can easily unsubscribe
let debouncedUpdateBodySizeCss: () => void
let unsubscribableEvents: (EventName | EventUnsubscribeFunction)[] = []

interface Bounds {
  right: number
  bottom: number
}
function computePostsBounds(postClassName: string): Bounds {
  const posts = document.getElementsByClassName(postClassName)
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft || 0

  let right = Math.max(1024, document.documentElement.clientWidth)
  let bottom = Math.max(1024, document.documentElement.clientHeight)
  for (let i = 0; i < posts.length; i += 1) {
    const rect = posts[i].getBoundingClientRect()
    right = Math.max(right, scrollLeft + rect.right)
    bottom = Math.max(bottom, scrollTop + rect.bottom)
  }
  return { right, bottom }
}

function updateBodySizeCss(postClassName: string, margin: number = 0): void {
  const { right, bottom } = computePostsBounds(postClassName)
  document.body.style.width = `${Math.round(right + margin)}px`
  document.body.style.height = `${Math.round(bottom + margin)}px`
}

function subscribeUpdateBodySizeCss(postClassName: string, events: SubscribeEvent[] = [], margin: number = 0): void {
  debouncedUpdateBodySizeCss = debounce((): void => updateBodySizeCss(postClassName, margin), 250)
  unsubscribableEvents = events.map((subscribeEvent: SubscribeEvent): UnsubscribeEvent => {
    if (typeof subscribeEvent === 'function') {
      return subscribeEvent(debouncedUpdateBodySizeCss)
    }
    document.body.addEventListener(subscribeEvent, debouncedUpdateBodySizeCss)
    return subscribeEvent
  })
}

function unsubscribeUpdateBodySizeCss(): void {
  unsubscribableEvents.forEach((unsubscribeEvent: UnsubscribeEvent): void => {
    if (typeof unsubscribeEvent === 'function') {
      unsubscribeEvent()
    } else {
      document.body.removeEventListener(unsubscribeEvent, debouncedUpdateBodySizeCss)
    }
  })
}

export { subscribeUpdateBodySizeCss, unsubscribeUpdateBodySizeCss }
