// @file logger composable
import { isDebugMode } from '@@/bits/flip'
import { onMounted, onUnmounted, onUpdated } from 'vue'

/**
 * ## Usage
 *
 * ```
 * import { useLogger } from '@@/vuecomposables/logger'
 *
 * const logger = useLogger('demo')
 *
 * logger.log('hi') // demo: hi
 *```
 */
export default function useLogger(
  context: string,
  options: { log: boolean; logLifecycleHooks: boolean } = { log: true, logLifecycleHooks: true },
): { log; warn; error } {
  const shouldLog = isDebugMode
  const { log, logLifecycleHooks } = options

  function logIfDebugMode(message: string, ...args): void {
    if (!log || !shouldLog) return
    console.log(message, ...args) // goodcheck-disable-line
  }
  function devLog(message: string, ...args): void {
    logIfDebugMode(`🪵 %c${context}:\n`, 'color: #FF6F00; font-weight: bold', message, ...args)
  }

  if (logLifecycleHooks) {
    onMounted(() => {
      logIfDebugMode(`🪵 %c${context}: %cmounted`, 'color: #FF6F00; font-weight: bold', 'font-weight: bold')
    })
    onUpdated(() => {
      logIfDebugMode(`🪵 %c${context}: %cupdated`, 'color: #FF6F00; font-weight: bold', 'font-weight: bold')
    })
    onUnmounted(() => {
      logIfDebugMode(`🪵 %c${context}: %cunmounted`, 'color: #FF6F00; font-weight: bold', 'font-weight: bold')
    })
  }

  return {
    ...console,
    log: devLog,
  }
}
