// @file Async function to load the Google Maps API
import { getHyphenatedCurrentLocale, lang } from '@@/bits/current_locale'
import { loadJs } from '@@/bits/load'
import { availableLocales } from '@@/bits/locale'

const GOOGLE_API_KEY = 'AIzaSyDVS4t6WZpqHnbglX3YM6W3SsWldcgKx-A' // staging API key for map, production API is provided via starting state
const CALLBACK_NAME = '__googleMapsApiOnLoadCallback'
const DEFAULT_TIMEOUT = 10000

// these locales have more specific language codes, eg zh-CN vs zh-HK which google maps supports
// https://developers.google.com/maps/faq#languagesupport
const localesWithSpecificLangCodes = availableLocales.filter((locale) =>
  ['zh', 'en', 'fr', 'pt', 'es'].some((prefix) => locale.standard.startsWith(prefix)),
)

// for specific lang codes (eg 'en-GB') supported by maps, return that specific lang code
// otherwise, return just `lang` (eg 'de')
const mapApiLanguageParameter: string = (() => {
  for (const locale of localesWithSpecificLangCodes) {
    // for eg, if local.standard was 'en_GB', lang would be 'en' and hyphenatedCurrentLocale would be 'en-GB'
    if (locale.standard.startsWith(lang)) {
      return getHyphenatedCurrentLocale()
    }
  }
  return lang
})()

async function loadGoogleMapsApi(
  googleApiKey?: string,
  options: { timeout?: number; region?: string } = {},
): Promise<void> {
  return await new Promise((resolve, reject): void => {
    // If the Google Maps API is already loaded, resolve immediately
    if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
      resolve()
      return
    }

    // Reject the promise after a timeout
    const timeoutId = setTimeout((): void => {
      window[CALLBACK_NAME] = (): void => {}
      reject(new Error('Could not load the Google Maps API'))
    }, options.timeout ?? DEFAULT_TIMEOUT)

    // Hook up the on load callback
    window[CALLBACK_NAME] = (): void => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId)
      }
      resolve()
      window[CALLBACK_NAME] = (): void => {}
    }

    // Build the URL to load the Google Maps API
    const url = new URL('https://maps.googleapis.com/maps/api/js')
    url.searchParams.set('key', googleApiKey ?? GOOGLE_API_KEY)
    url.searchParams.set('callback', CALLBACK_NAME)
    url.searchParams.set('libraries', 'geometry,places')
    url.searchParams.set('language', mapApiLanguageParameter)
    if (options.region != null) url.searchParams.set('region', options.region)
    void loadJs(url.toString())
  })
}

export default loadGoogleMapsApi
