// @file Composable to add double click to post functionality to surface
import { useSurfaceOnboardingDemoPadletPanelStore } from '@@/pinia/surface_onboarding_demo_padlet_panel_store'
import { useSurfacePermissionsStore } from '@@/pinia/surface_permissions'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import { onMounted, onUnmounted } from 'vue'

type MouseEventHandler = (e: MouseEvent) => void
type HTMLElementWithMouseHandlers = HTMLElement & {
  $_onDblClick?: MouseEventHandler
}

export function useDoubleClickToPost(wallContainerEl?: HTMLElement, wishListEl?: HTMLElement): void {
  const surfacePostsStore = useSurfacePostsStore()
  const surfacePermissionsStore = useSurfacePermissionsStore()
  const surfaceDemoPadletPanelStore = useSurfaceOnboardingDemoPadletPanelStore()

  onMounted(() => {
    const defaultWallContainerEl = document.querySelector<HTMLElement>('#wall-container')
    const wallContainerElement = wallContainerEl ?? defaultWallContainerEl

    let wishListElement = wishListEl ?? document.querySelector<HTMLElement>('#wish-list')

    let timelineWishListEl = document.querySelector<HTMLElement>('.wish-list-content')
    let padletSectionsEl = document.querySelector<HTMLElement>('padlet-sections')

    const handleDoubleClick: MouseEventHandler = (e) => {
      if (surfaceDemoPadletPanelStore.isDemoPadletPanelDesktop) return
      if (!surfacePermissionsStore.canIPost) return
      // Sometimes these elements are lazily loaded and are not available during onMounted => try to fallback to the default elements just in case
      wishListElement = wishListEl ?? document.querySelector<HTMLElement>('#wish-list')
      timelineWishListEl = timelineWishListEl ?? document.querySelector<HTMLElement>('.wish-list-content')
      padletSectionsEl = padletSectionsEl ?? document.querySelector<HTMLElement>('padlet-sections')

      if (
        e.target === defaultWallContainerEl ||
        e.target === timelineWishListEl ||
        e.target === wishListElement ||
        e.target === wallContainerElement ||
        (e.target instanceof HTMLElement && e.target.classList.contains('wish-wrapper')) ||
        (e.target instanceof HTMLElement && e.target.classList.contains('timeline-section-posts-center-wrapper')) ||
        (e.target instanceof HTMLElement && e.target.classList.contains('timeline-sections-and-add-button-wrapper')) ||
        (e.target instanceof HTMLElement && e.target.classList.contains('wish-list-scrolling-wrapper')) ||
        (e.target instanceof HTMLElement && e.target.classList.contains('surface-section')) ||
        (e.target instanceof HTMLElement && e.target.id.startsWith('group-posts')) ||
        e.target === padletSectionsEl
      ) {
        e.preventDefault()
        const position = {
          left: 0,
          top: 0,
        }

        if (wishListElement != null) {
          const { left, top } = wishListElement?.getBoundingClientRect()
          const { scrollLeft, scrollTop } = wishListElement
          position.left = e.pageX - left + scrollLeft
          position.top = e.pageY - top + scrollTop
        }
        surfacePostsStore.startNewPost({ attributes: { ...position } })
      }
    }

    wallContainerElement?.addEventListener('dblclick', handleDoubleClick)
    if (wallContainerElement != null) {
      ;(wallContainerElement as HTMLElementWithMouseHandlers).$_onDblClick = handleDoubleClick
    }
  })

  onUnmounted(() => {
    const wallContainerElement = wallContainerEl ?? document.querySelector<HTMLElement>('#wall-container')
    const handleDoubleClick = (wallContainerElement as HTMLElementWithMouseHandlers)?.$_onDblClick
    if (handleDoubleClick != null) wallContainerEl?.removeEventListener('dblclick', handleDoubleClick)
  })
}
