<script setup lang="ts">
import useLogger from '@@/vuecomposables/logger'
import { watch } from 'vue'

const props = defineProps<{
  message: string
}>()
const srLogger = useLogger('sr-speech-notifs', { logLifecycleHooks: false, log: true })

watch(
  () => props.message,
  (updatedMessage) => {
    srLogger.log(updatedMessage)
  },
)
</script>
<template>
  <div id="speech-notifications" class="sr-only">
    <div id="speech-notifications-message" aria-live="polite">
      {{ message }}
    </div>
  </div>
</template>
