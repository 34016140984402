<script setup lang="ts">
import { trackEvent } from '@@/bits/analytics'
import { subscribeUpdateBodySizeCss, unsubscribeUpdateBodySizeCss } from '@@/bits/body_sizing'
import {
  PasteType,
  extractPostAttributesArrayFromClipboardData,
  extractPostAttributesFromClipboardData,
  removeAttachmentFromPostAttributes,
} from '@@/bits/clipboard'
import device from '@@/bits/device'
import { isActiveElementAnInputField, isEventOnAnInputField } from '@@/bits/dom'
import { isAppUsing } from '@@/bits/flip'
import window from '@@/bits/global'
import { __ } from '@@/bits/intl'
import { navigateTo } from '@@/bits/location'
import loadMapApi from '@@/bits/map_api_loader'
import { isNavigatedFromSubmissionRequest } from '@@/bits/surface_share_links_helper'
import { defineAsyncComponent, stableSync } from '@@/bits/vue'
import { useContentPickerContainerSizeStore } from '@@/pinia/content_picker_container_size'
import type { GlobalSnackbarNotification } from '@@/pinia/global_snackbar'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useScreenReaderNotificationsStore } from '@@/pinia/screen_reader_notifications'
import { SEARCH_BAR_DEFAULT_TOP, useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceAIChatStore } from '@@/pinia/surface_ai_chat_store'
import { useCommentsStore } from '@@/pinia/surface_comments'
import { useSurfaceContainerSizeStore } from '@@/pinia/surface_container_size'
import { useSurfaceDraftsStore } from '@@/pinia/surface_drafts'
import { useSurfaceGuestStore } from '@@/pinia/surface_guest_store'
import { useSurfaceMapStore } from '@@/pinia/surface_map'
import { useSurfaceOnboardingDemoPadletPanelStore } from '@@/pinia/surface_onboarding_demo_padlet_panel_store'
import { useSurfaceOnboardingPanelStore } from '@@/pinia/surface_onboarding_panel'
import { useSurfacePermissionsStore } from '@@/pinia/surface_permissions'
import { useSurfacePostPropertiesStore } from '@@/pinia/surface_post_properties'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import { useReactionsStore } from '@@/pinia/surface_reactions'
import { useSurfaceShareLinksStore } from '@@/pinia/surface_share_links'
import { useSurfaceStartingStateStore } from '@@/pinia/surface_starting_state'
import { useSurfaceWishArrangementStore } from '@@/pinia/surface_wish_arrangement'
import type { Id } from '@@/types'
import ScreenReaderSpeechNotifications from '@@/vuecomponents/ScreenReaderSpeechNotifications.vue'
import SurfacePanels from '@@/vuecomponents/SurfacePanels.vue'
import { useNativeBridgeListeners } from '@@/vuecomposables/native_bridge_listeners'
import { useDoubleClickToPost } from '@@/vuecomposables/surface_double_click_to_post'
import { useSurfaceFetchInitialState } from '@@/vuecomposables/surface_fetch_initial_state'
import { usePadletPickerCreateState } from '@@/vuecomposables/usePadletPickerLastCreateState'
import { isEmpty } from '@@/vuexstore/helpers/post'
import { GroupByTypes } from '@padlet/arvo'
import { storeToRefs } from 'pinia'
import type { KeyBindingMap } from 'tinykeys'
import tinykeys from 'tinykeys'
import { computed, nextTick, onBeforeMount, onBeforeUnmount, onMounted, ref, watch } from 'vue'

const SurfaceActionBar = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceActionBar.vue'))
const SurfaceGuestIdLabel = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceGuestIdLabel.vue'))
const SurfaceContainerHeaderPad = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceContainerHeaderPad.vue'))
const SurfaceContainerGridMasonry = defineAsyncComponent(
  () => import('@@/vuecomponents/SurfaceContainerGridMasonry.vue'),
)
const SurfaceContainerGrid = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceContainerGrid.vue'))
const SurfaceContainerMap = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceContainerMap.vue'))
const SurfaceContainerTimelineClassic = defineAsyncComponent(
  () => import('@@/vuecomponents/SurfaceContainerTimelineClassic.vue'),
)
const SurfaceContainerTimeline = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceContainerTimeline.vue'))
const SurfaceContainerMatrix = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceContainerMatrix.vue'))
const SurfaceContainerStream = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceContainerStream.vue'))
const SurfaceContainerCanvas = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceContainerCanvas.vue'))
const SurfaceContainerSectionBreakout = defineAsyncComponent(
  () => import('@@/vuecomponents/SurfaceContainerSectionBreakout.vue'),
)
const SurfaceContainerSubmissionRequest = defineAsyncComponent(
  () => import('@@/vuecomponents/SurfaceContainerSubmissionRequest.vue'),
)
const SurfaceAddPost = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceAddPost.vue'))
const SurfaceMagicWallFeedbackPopup = defineAsyncComponent(
  () => import('@@/vuecomponents/SurfaceMagicWallFeedbackPopup.vue'),
)

const isMapApiLoaded = ref(false)
const isApp = device.app
const wallContainerMainElementRef = ref<HTMLDivElement | undefined>(undefined)
const reactionIdsByPostId = ref<Record<Id, Id[]>>({})
const commentIdsByPostId = ref<Record<Id, Id[]>>({})
const headerBackgroundTop = ref<number>(0)
const pageScrollHandlerTicking = ref<boolean>(false)

const { googleApiKeyForMap, currentCountryCode } = storeToRefs(useSurfaceStartingStateStore())
const { amIOwner, canIPost, amIRegistered } = storeToRefs(useSurfacePermissionsStore())

const surfaceAIChatStore = useSurfaceAIChatStore()
const { magicWallFirstOpen } = storeToRefs(surfaceAIChatStore)

const surfaceStore = useSurfaceStore()
const {
  isEmbedded,
  isOnline,
  format,
  isOverlayVisible,
  isCanvas,
  isGrid,
  isMap,
  isScreenshotMode,
  isStream,
  isTable,
  isTimeline,
  isTimelineV1,
  isTimelineV2,
  isMatrix,
  isMobileLayout,
  isDesktopLayout,
  xHeader,
  isSubscriptionPaused,
  canUseSections,
  headerHeight,
  headerColor,
  isSectionBreakout,
  isSubmissionRequest,
  isFrozen,
  hasSidePanelOutsideSurface,
  user,
  isSearchBarInEditMode,
  isSidePanelRounded,
} = storeToRefs(surfaceStore)
const { refresh, resizeHeader } = surfaceStore
const { isContainerSmallerThanTabletLandscape, isContainerSmallerThanTabletPortrait } = storeToRefs(
  useSurfaceContainerSizeStore(),
)
const surfacePostsStore = useSurfacePostsStore()
const { postBeingEditedCid } = storeToRefs(surfacePostsStore)
const { startNewPost } = surfacePostsStore
const { wishGroupBy } = storeToRefs(useSurfaceWishArrangementStore())
const { reactionIdsByPost } = storeToRefs(useReactionsStore())
const { commentIdsByPost } = storeToRefs(useCommentsStore())
const { setSnackbar } = useGlobalSnackbarStore()
const { startPickingLocation } = useSurfaceMapStore()
const { startNewDraft } = useSurfaceDraftsStore()
const { showPostPublishedSnackbar } = useSurfaceShareLinksStore()
const { observeSurfaceContainerSize } = useSurfaceContainerSizeStore()
const { observeContainerSize } = useContentPickerContainerSizeStore()
const { shouldHandlePasteToAddAttachment } = storeToRefs(useSurfacePostPropertiesStore())
const { setIsActionBarMounted } = useSurfaceOnboardingPanelStore()
const { demoPadletPanelVisibility, isDemoPadletPanelDesktop } = storeToRefs(useSurfaceOnboardingDemoPadletPanelStore())
const { latestScreenReaderMessage } = storeToRefs(useScreenReaderNotificationsStore())
const surfaceGuestStore = useSurfaceGuestStore()

// Getters
const isSidePanelDesktop = computed<boolean>(() => {
  return hasSidePanelOutsideSurface.value && !isContainerSmallerThanTabletPortrait.value
})

const isSidePanelDesktopRounded = computed<boolean>(() => {
  return isSidePanelRounded.value && isSidePanelDesktop.value
})

const xMagicWallFeedbackPopup = computed<boolean>(() => {
  return !isMobileLayout.value && magicWallFirstOpen.value
})

// Methods
function handlePageScroll(): void {
  if (!pageScrollHandlerTicking.value && !isMap.value) {
    window.requestAnimationFrame(() => {
      pageScrollHandlerTicking.value = false

      // Set header underneath background top position to hide it when the page scrolls the header and wishlist away
      const wallContainerEl = document.querySelector('#wall-container')
      if (wallContainerEl != null) {
        headerBackgroundTop.value = -wallContainerEl.scrollTop
      }

      // Search bar is 16px from the top
      // When in edit mode, we keep it fixed so users can scroll to see the posts while searching
      const searchBarTop = SEARCH_BAR_DEFAULT_TOP + (isSearchBarInEditMode.value ? 0 : headerBackgroundTop.value)
      useSurfaceStore().updateSearchBarTop(searchBarTop)
    })

    pageScrollHandlerTicking.value = true
  }
}

async function loadMap(): Promise<void> {
  if (!isMapApiLoaded.value) {
    await loadMapApi(googleApiKeyForMap.value, {
      region: currentCountryCode.value,
    })
    isMapApiLoaded.value = true
  }
}

async function handleFormatChange(): Promise<void> {
  /**
   * Styles for `grid` format (with or without sections) live entirely within Vue components.
   * We don't want to set `data-layout` to `shelf` since it will use old styles and mess up the view.
   */
  if (format.value === 'map') {
    await loadMap()
  }
  if (isSectionBreakout.value) {
    document.documentElement.setAttribute('data-layout', format.value === 'map' ? 'map' : 'grid')
    return
  }
  document.documentElement.setAttribute('data-layout', format.value === 'shelf' ? 'grid' : format.value)
}

function enableNewPostHandlers(): void {
  window.addEventListener('paste', handleOnPaste as (e: Event) => void)
}

function disableNewPostHandlers(): void {
  window.removeEventListener('paste', handleOnPaste as (e: Event) => void)
}

function refreshContentIfVisible() {
  if (document.visibilityState === 'visible') {
    refresh({ isAutoRefresh: true })
  }
}

function handleOnPaste(event: ClipboardEvent): void {
  event.stopPropagation()
  if (!canIPost.value) return
  // Check if content picker element is rendered in the DOM. If so, should not handle paste
  const contentPickerEl = document.getElementById('content-picker')
  if (contentPickerEl) return

  // Check if edit thumbnail modal is rendered in the DOM. If so, should not handle paste
  const editThumbnailModalEl = document.getElementById('edit-thumbnail-modal')
  if (editThumbnailModalEl) return

  if (isEventOnAnInputField(event)) {
    return
  }

  let data = extractPostAttributesArrayFromClipboardData(event.clipboardData)

  // When `shouldHandlePasteToAddAttachment` is `false`, we don't handle `PasteType.File`
  // and don't set the `attachment` field in `postAttributes` as it will add the attachment to the draft
  if (!shouldHandlePasteToAddAttachment.value) {
    data = removeAttachmentFromPostAttributes(data)
  }

  if (data.length === 0) return

  // Pasting texts from some applications like Microsoft Word may also paste an image version of the text
  // we want to treat them as text
  const isRichTextPastedAsFile =
    event.clipboardData?.types?.includes('text/rtf') && event.clipboardData?.types?.includes('Files')

  // Create a post with attachment without filename
  // when PasteType is File
  const attachment = extractPostAttributesFromClipboardData(data, PasteType.File)

  if (attachment && !isRichTextPastedAsFile) {
    startNewDraft({
      attributes: attachment.postAttributes,
      shouldStartEditing: true,
    })

    trackEvent('Posts', `Added draft by pasting ${attachment.pasteType}`)
    return
  }

  // Create a new post when PasteType is Link or Text or PostContentHash
  const dataWithoutFile = data.filter((datum) => {
    return datum?.pasteType !== PasteType.File
  })

  const isEmptyPost = dataWithoutFile[0] == null || isEmpty(dataWithoutFile[0].postAttributes)
  if (isEmptyPost) return

  startNewDraft({
    attributes: dataWithoutFile[0].postAttributes,
    shouldStartEditing: true,
  })
  trackEvent('Posts', `Added draft by pasting ${dataWithoutFile[0].pasteType}`)
}

function handleOnKeydownC(event: KeyboardEvent): void {
  if (!canIPost.value) return
  if (postBeingEditedCid.value) return
  if (isOverlayVisible.value) return
  if (isActiveElementAnInputField()) return
  event.preventDefault()

  if (isMap.value) {
    trackEvent('Keyboard Shortcuts', 'Triggered shortcut', null, null, {
      target: 'surface-shortcut-key-c',
      action: 'pick location',
    })
    startPickingLocation()
  } else {
    trackEvent('Keyboard Shortcuts', 'Triggered shortcut', null, null, {
      target: 'surface-shortcut-key-c',
      action: 'start new post',
    })
    startNewPost({})
  }
}

function handleOnKeydownModF(event: KeyboardEvent): void {
  if (postBeingEditedCid.value) return
  if (isOverlayVisible.value) return
  if (isActiveElementAnInputField()) return
  event.preventDefault()
  // Couldn't get Vue ref to work so we will use direct DOM APIs here.
  // TODO: Use Vue ref instead.
  document.getElementById('surface-search-bar')?.querySelector('input')?.select()
  useSurfaceStore().updateSearchBarTop(SEARCH_BAR_DEFAULT_TOP)
  trackEvent('Surface search', 'Used Ctrl F to search')
}

// Watcher
watch(isOnline, () => {
  refresh({ isAutoRefresh: true })
})

watch(canIPost, (canIPost) => {
  if (canIPost) {
    enableNewPostHandlers()
  } else {
    disableNewPostHandlers()
  }
})

watch(format, () => {
  handleFormatChange()
})

watch(
  reactionIdsByPost,
  (newValue: Record<Id, Id[]>) => {
    stableSync(reactionIdsByPostId.value, newValue)
  },
  { immediate: true },
)

watch(
  commentIdsByPost,
  (newValue) => {
    stableSync(commentIdsByPostId.value, newValue as Record<Id, Id[]>)
  },
  { immediate: true },
)

watch(isSearchBarInEditMode, () => {
  handlePageScroll()
})
// End watcher

let removeKeyboardShortcutsListener = (): void => {}

// Lifecycle hooks
onMounted(async () => {
  refresh({ isAutoRefresh: false })

  // Watch for size changes in surface container and update surface container size store
  observeSurfaceContainerSize(wallContainerMainElementRef.value as HTMLElement)
  // and content picker container size store
  observeContainerSize(wallContainerMainElementRef.value as HTMLElement)

  // Ensure new posts are loaded. Realtime might be disconnected when invisible, e.g. on mobile.
  document.addEventListener('visibilitychange', refreshContentIfVisible)

  if (isMap.value) {
    await loadMap()
  }
  nextTick(() => {
    if (canIPost.value) {
      enableNewPostHandlers()
    }
  })
  if (!xHeader.value) {
    // mobile app with native header
    resizeHeader({ height: 0 })
  }

  // Ensure that export as image captures all posts
  if (isScreenshotMode.value && isCanvas.value) {
    const hookToAllStoreChanges = ((store) => store?.subscribe.bind(store))(window.app?.$store)
    const events = ['load', 'beethoven-load', 'error', 'beethoven-error', hookToAllStoreChanges ?? '']
    const extraMarginInPx = 200
    subscribeUpdateBodySizeCss('wish', events, extraMarginInPx)
  }
  if (isSubscriptionPaused.value && amIOwner.value) {
    const snackbarData: GlobalSnackbarNotification = {
      message: __('Your subscription is paused. Your padlets are read-only until it resumes.'),
      persist: true,
    }
    if (!isApp) {
      snackbarData.actionText = __('Go to Settings')
      snackbarData.actionTextActions = [() => navigateTo('/dashboard/settings/billing')]
    }
    setSnackbar(snackbarData)
  }

  const shortcuts: KeyBindingMap = {
    '$mod+KeyF': handleOnKeydownModF,
  }

  if (!isDemoPadletPanelDesktop.value) {
    shortcuts.KeyC = handleOnKeydownC
  }

  if (isAppUsing('keyboardShortcutsSetting') && user.value.is_keyboard_shortcuts_enabled === false) {
    delete shortcuts.KeyC
  }

  removeKeyboardShortcutsListener = tinykeys(window, shortcuts)

  if (isNavigatedFromSubmissionRequest() && !isSubmissionRequest.value) {
    showPostPublishedSnackbar()
  }

  if (isAppUsing('padletPickerV2')) {
    usePadletPickerCreateState().resumeIfApplicable()
  }
})

onBeforeUnmount(() => {
  disableNewPostHandlers()
  unsubscribeUpdateBodySizeCss()
  removeKeyboardShortcutsListener()
  document.removeEventListener('visibilitychange', refreshContentIfVisible)
})
// END LIFECYCLE HOOKS

// On setup
function onSetup(): void {
  if (!device.mobile) {
    useDoubleClickToPost()
  }
  if (device.app) {
    useNativeBridgeListeners({ for: 'surface' })
  }
}
onSetup()

onBeforeMount(() => {
  useSurfaceFetchInitialState().fetchInitialState()
})
</script>

<template>
  <main
    ref="wallContainerMainElementRef"
    :class="[
      'flex',
      'justify-end',
      isContainerSmallerThanTabletLandscape && 'flex-col',
      // In screenshot mode, we want the document to be scrollable so the export services can
      // capture the entire page. Using `100vw` and `100vh` limits this possibility. Exceptions
      // is only the old Timeline format which works fine with the viewport units.
      {
        'w-full h-full overflow-auto': isScreenshotMode && !isTimelineV1,
        'w-screen h-vh100': !isScreenshotMode || isTimelineV1,
      },
      '@container/surface-container',
    ]"
    :style="{
      // Adding this as a temporary fix to the issue where the surface panels are hidden under the side panels
      // See this issue: https://issues.chromium.org/issues/368873086
      contain: 'layout',
    }"
  >
    <ScreenReaderSpeechNotifications :message="latestScreenReaderMessage" />
    <SurfaceContainerHeaderPad
      v-if="isDesktopLayout && !isMap"
      :height="headerHeight"
      :color="headerColor"
      :top="headerBackgroundTop"
    />
    <!-- Submission request with `map` layout will fallback to surface-container-map below -->
    <SurfaceContainerSubmissionRequest v-if="isSubmissionRequest && !isMap" />
    <!-- Section breakout with `map` layout will fallback to surface-container-map below -->
    <SurfaceContainerSectionBreakout
      v-else-if="isSectionBreakout && !isMap"
      :reaction-ids-by-post-id="reactionIdsByPostId"
      :comment-ids-by-post-id="commentIdsByPostId"
      @scroll="handlePageScroll"
    />
    <div v-else-if="isMap" class="w-full">
      <surface-container-map
        v-if="isMapApiLoaded"
        :reaction-ids-by-post-id="reactionIdsByPostId"
        :comment-ids-by-post-id="commentIdsByPostId"
      />
    </div>
    <SurfaceContainerGridMasonry
      v-else-if="isTable || (isGrid && (!canUseSections || wishGroupBy === GroupByTypes.None))"
      :reaction-ids-by-post-id="reactionIdsByPostId"
      :comment-ids-by-post-id="commentIdsByPostId"
      @scroll="handlePageScroll"
    />
    <SurfaceContainerGrid
      v-else-if="isGrid && canUseSections && wishGroupBy !== GroupByTypes.None"
      :reaction-ids-by-post-id="reactionIdsByPostId"
      :comment-ids-by-post-id="commentIdsByPostId"
      @scroll="handlePageScroll"
    />
    <SurfaceContainerTimelineClassic
      v-else-if="isTimelineV1 || (isTimeline && !canUseSections)"
      :reaction-ids-by-post-id="reactionIdsByPostId"
      :comment-ids-by-post-id="commentIdsByPostId"
    />
    <SurfaceContainerTimeline
      v-else-if="isTimelineV2 || (isTimeline && canUseSections)"
      :reaction-ids-by-post-id="reactionIdsByPostId"
      :comment-ids-by-post-id="commentIdsByPostId"
      @scroll="handlePageScroll"
    />
    <SurfaceContainerMatrix
      v-else-if="isMatrix"
      :reaction-ids-by-post-id="reactionIdsByPostId"
      :comment-ids-by-post-id="commentIdsByPostId"
      @scroll="handlePageScroll"
    />
    <SurfaceContainerStream
      v-else-if="isStream"
      :reaction-ids-by-post-id="reactionIdsByPostId"
      :comment-ids-by-post-id="commentIdsByPostId"
      @scroll="handlePageScroll"
    />
    <SurfaceContainerCanvas
      v-else
      :reaction-ids-by-post-id="reactionIdsByPostId"
      :comment-ids-by-post-id="commentIdsByPostId"
      @scroll="handlePageScroll"
    />
    <template v-if="!isApp && !isEmbedded && !isScreenshotMode">
      <SurfaceActionBar
        v-if="!isSubmissionRequest && demoPadletPanelVisibility.showSurfaceActionBar"
        :class="['shrink-0', isDesktopLayout && 'w-18 h-full', isMobileLayout && 'w-full h-13']"
        @hook:mounted="setIsActionBarMounted(true)"
      />
      <SurfaceGuestIdLabel
        v-else-if="
          surfaceGuestStore.shouldEnableAnonymousAttribution && !amIRegistered && !isContainerSmallerThanTabletPortrait
        "
        :class="['fixed top-4 end-4']"
      />
    </template>

    <SurfacePanels :reaction-ids-by-post-id="reactionIdsByPostId" :comment-ids-by-post-id="commentIdsByPostId" />
    <SurfaceAddPost v-if="!isFrozen && demoPadletPanelVisibility.showSurfaceAddPost" />
    <SurfaceMagicWallFeedbackPopup v-if="xMagicWallFeedbackPopup" />
    <div v-if="isSidePanelDesktopRounded" class="h-4 w-4 end-0 top-0 absolute corner-top"></div>
    <div v-if="isSidePanelDesktopRounded" class="h-4 w-4 end-0 bottom-0 absolute corner-bottom"></div>
  </main>
</template>

<style lang="scss" scoped>
@import '@@/styles/3/modules/all';
.corner-top {
  background: radial-gradient(circle at bottom left, transparent 16px, black 16px);
  z-index: $sidepanel-zindex;

  [dir='rtl'] & {
    background: radial-gradient(circle at bottom right, transparent 16px, black 16px);
  }
}

.corner-bottom {
  background: radial-gradient(circle at top left, transparent 16px, black 16px);
  z-index: $sidepanel-zindex;

  [dir='rtl'] & {
    background: radial-gradient(circle at top right, transparent 16px, black 16px);
  }
}
</style>
